import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserManager, Log, User, UserManagerSettings } from 'oidc-client';

import { OIDC_SETTINGS } from '../tokens';

@Injectable()
export class AuthService {
  public userLoadedEvent: EventEmitter<User> = new EventEmitter<User>();
  public signoutRequestedEvent: EventEmitter<void> = new EventEmitter<void>();
  public currentUser: User;
  public loggedIn = false;

  public mgr: UserManager;

  constructor(
    private http: HttpClient,
    @Inject(OIDC_SETTINGS) oidcSettings: UserManagerSettings
  ) {
    this.mgr = new UserManager(oidcSettings);
    this.mgr.clearStaleState();
    Log.logger = console;
    Log.level = Log.ERROR;

    this.mgr.events.addUserLoaded(() => {
      console.log('[AUTH] User loaded.');
      this.mgr.getUser().then((user) => {
        this.loggedIn = true;
        this.currentUser = user;
        this.userLoadedEvent.emit(user);
      });
    });

    this.mgr.events.addUserUnloaded(() => {
      console.log('[AUTH] User unloaded');
      this.loggedIn = false;
    });

    this.mgr.events.addAccessTokenExpiring(() => {
      console.log('[AUTH] Token is expiring');
    });

    this.mgr.events.addAccessTokenExpired(() => {
      console.log('[AUTH] Token is expired');
      this.removeUser();
      this.startSigninMainWindow({
        startUrl: window.location.pathname + window.location.search,
      });
    });

    this.mgr.events.addSilentRenewError((err) => {
      console.log('[AUTH] Silent renew error: ', err.message);
      this.startSigninMainWindow({
        startUrl: window.location.pathname + window.location.search,
      });
    });

    this.mgr.events.addUserSignedOut(() => {
      console.log('[AUTH] User signed out');
      this.startSigninMainWindow({
        startUrl: window.location.pathname + window.location.search,
      });
    });
  }

  public clearState() {
    return this.mgr
      .clearStaleState()
      .then(() => {
        console.log('clearStateState success');
      })
      .catch((err) => {
        console.log('clearStateState error', err.message);
      });
  }

  public async checkSession() {
    try {
      await this.getUserInfo();
      return true;
    } catch {
      this.revokeSession();
    }
  }

  public revokeSession() {
    this.removeUser();
    this.startSigninMainWindow();
  }

  public getUser() {
    return this.mgr
      .getUser()
      .then((user) => {
        if (user) {
          this.loggedIn = true;
          this.currentUser = user;
          this.userLoadedEvent.emit(user);
        } else {
          this.loggedIn = false;
        }
        return this.loggedIn;
      })
      .catch(() => {
        this.loggedIn = false;
        return false;
      });
  }

  public removeUser() {
    return this.mgr
      .removeUser()
      .then(() => {
        this.userLoadedEvent.emit(null);
        console.log('user removed');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public startSigninMainWindow(state: Record<string, unknown> = {}) {
    return this.mgr
      .signinRedirect({ data: state })
      .then(() => {
        console.log('signinRedirect done');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public endSigninMainWindow(startUrl?: string): Promise<unknown> {
    return this.mgr
      .signinRedirectCallback(startUrl)
      .then((user) => {
        this.loggedIn = true;
        this.currentUser = user;
        this.userLoadedEvent.emit(user);
        return user.state;
      })
      .catch((err) => {
        console.log(err);
        this.loggedIn = false;
        return {};
      });
  }

  public startSilentRefresh() {
    return this.mgr.signinSilentCallback();
  }

  public startSignoutMainWindow() {
    if (this.signoutRequestedEvent.observers.length === 0) {
      // No subscribers, proceed with signout
      this.confirmSignoutMainWindow();
    }
    else {
      // Emit an event asking for signout confirmation
      this.signoutRequestedEvent.emit();
    }
  }

  public confirmSignoutMainWindow() {
    // Need to get user here to make sure we get the most recent id_token.
    return this.mgr.getUser().then((user) => {
      return this.removeUser().then(() => {
        return this.mgr
          .signoutRedirect({
            id_token_hint: user.id_token,
            extraQueryParams: {
              client_id: this.mgr.settings.client_id,
            },
          })
          .then((resp) => {
            console.log('signed out', resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    });
  }

  public endSignoutMainWindow() {
    return this.mgr
      .signoutRedirectCallback()
      .then((resp) => {
        console.log('signed out', resp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public async getUserInfo() {
    await this.mgr.metadataService.getMetadata();

    const url = this.mgr.settings.metadata.userinfo_endpoint;

    return this.http.get(url).toPromise();
  }
}
